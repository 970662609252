.nav-item{width: 50%;}
.nav-item .nav-link{width: 100%;color: #ea7826;}

.pending_box{border: 1px solid #d7d7d7; margin: 8px 0; background: #f8f8f8; padding: 8px 12px;
    border-radius: 8px; -moz-border-radius: 8px;-webkit-border-radius: 8px;-o-border-radius: 8px;
}
.tab-pane{padding-bottom: 80px;}

.pending_box .pending_bxtitle{ font-size: 14px; font-weight: 600; margin-bottom: 10px;}
.pending_box .pending_bxtitle_text{ font-size: 13px; font-weight: 600; margin-bottom: 10px;}
.start_btn{font-size: 14px; padding: 5px 14px 7px; line-height: 16px;}
.modal-title{width: 100%; display: block;}
/*.pending_information_scroll{width: 100%; height: 290px; overflow: hidden; overflow-y: auto;}*/
.pending_information_scroll table{font-size: 13px;}
.pending_information_scroll table .form-control{font-size: 13px;}
.modal-content{height: 96vh;}

.tower_image_wrapper{position: relative; width: 100%; display: block; margin: 8px 0;}
.tower_image_wrapper img{ border: 1px solid #d7d7d7; padding: 5px;
    border-radius: 8px;-moz-border-radius: 8px;-webkit-border-radius: 8px;-o-border-radius: 8px;
}
.tower_image_wrapper .tower_imae_title{ text-align: center; font-size: 12px;}
a.delete_image {
    color: #fff;
    background: red;
    padding: 6px 9px;
    border-radius: 100%;
    line-height: 7px;
    font-size: 16px;
    width: 30px;
    position: absolute;
    height: 30px;
    top: 1px;
    right: 0px;
}

.modal-body{overflow-y: auto;}

.camera_icon {
    width: 100%;
    border: 2px solid #266a6c;
    padding: 8px;
    font-size: 9px;
}

.icon_ftsize{padding: 0 3px;
    float: left;
    font-size: 10px;
    margin: 0 3px;}

 .review_response_text{text-align: center;  
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 10px; font-weight: bold; margin-bottom: 0px;
   margin-top: -10px;}   
   .text-label{font-weight: bold;}
   .leftsidehide{
    display: none;
   }




