

  /* Input Checkbox start  */
.switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 25px;
  }
  
  label.switch {
    font-size: 80%;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .mar_45 {
    margin-top: -45px !important;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 30px;
    left: 8px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    -webkit-border-radius: 15px;
  }
  
  input:checked+.slider {
    background-color: #09ce3a;
  }
  
  input:focus+.slider {
    box-shadow: 0 0 1px #09ce3a;
  }
  
  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Input Checkbox end */
  