
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #ea7826;
    --bs-btn-border-color: #ea7826;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ea6608;
    --bs-btn-hover-border-color: #ea6608;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #ea6608;
    --bs-btn-active-border-color: #ea6608;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ea7826;
    --bs-btn-disabled-border-color: #ea7826;
}

/*#2d6263*/

.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #2d6263;
    --bs-btn-border-color: #2d6263;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #205c5d;
    --bs-btn-hover-border-color: #2d6263;
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #2d6263;
    --bs-btn-active-border-color: #205c5d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #2d6263;
    --bs-btn-disabled-border-color: #205c5d;
}
.header{width: 100%; border-bottom: 1px solid #000; min-height: 50px; background: #2d6263;
    background-image: linear-gradient(0deg, #2d6263, #0a8d90); text-align: center;
}

.header_title{font-size: 17px;color: #fff;line-height: 50px; display: block; font-weight: bold;}


.footer{position: fixed; z-index: 999; width: 100%; bottom: 0px; text-align: center; height: 50px;
   background: lightgray; padding-top: 14px; font-size: 14px; font-weight: bold; color: #182848;
}

/*.main_body{width: 100%; padding: 20px 20px 0px 20px; min-height: 400px;}*/
.main_body{width: 100%; padding: 4px 4px 0px 4px; min-height: 400px;}


/*---------------------
    HAMBURGER MENU
    ----------------------*/
.hamburger-menu {
    /*position: fixed;*/
    position: absolute;
    top: 0;
/*    left: 0;*/
       right: 0;
    padding: 14px;
    width: auto;
    height: 43px;
    cursor: pointer;
    z-index: 9998;
    background: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.bar,
.bar:after,
.bar:before {
    width: 30px;
    height: 3px;
}

.bar {
    position: relative;
    transform: translateY(10px);
    background: #fff;
    transition: all 0ms 300ms;
}

.bar.animate {
    background: rgba(255, 255, 255, 0);
}

.bar:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 10px;
    background: #fff;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar:after {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    background: #fff;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar.animate:after {
    top: 0;
    transform: rotate(45deg);
    background: #000;
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    background: #000;
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

/*---------------------
Mobiles Menu
----------------------*/
/*---------------------
Mobiles Menu - Design
----------------------*/
.mobile-menu ul {
    margin: 0;
    padding: 0;
}

.mobile-menu li {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    overflow: hidden;
    padding: 5px;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    padding-left: 15px; border-bottom: 1px solid #000;
}

.mobile-menu li:first-child {
    margin-top: 70px;
}

.mobile-menu li:hover {
    background: #CCCCCC;
}

.mobile-menu li a {
    text-decoration: none;
    color: black; font-weight: 500; font-size: 14px;
}

/*---------------------
Mobiles Menu - Slide IN
----------------------*/
.mobile-menu {
    top: 0;
    max-width: 250px;
/*    left: -100%;*/
right: -100%;
    width: 100%;
    background: #EBEBEB;
    color: black;
    height: 100%;
    position: fixed;
    z-index: 9997;
    overflow-y: auto;
    -webkit-transform: translate3d(0, 0, 205px);
    -moz-transform: translate3d(0, 0, 205px);
    transform: translate3d(0, 0, 205px);
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
}

.mobile-menu.active {
    right: 0;
/*    left: 0;*/
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
}

/*---------------------
Mobiles Menu - Dropdown Submenu
----------------------*/
.has-children:hover {
    cursor: hand;
}

.children {
    display: none;
}

.mobile-menu .children li:first-child {
    margin-top: 0px;
}

.icon-arrow {
    position: absolute;
    display: block;
    font-size: 0.7em;
    color: black;
    top: 5px;
    right: 10px;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transition: 0.6s;
    -webkit-transition: 0.6s;
    -moz-transition: 0.6s;
}

.icon-arrow:after {
    content: "▼";
}

.icon-arrow.open {
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    transition: 0.6s;
    -webkit-transition: 0.6s;
    -moz-transition: 0.6s;
}
.clearfix{clear: both;}

.project_text_title{font-size: 15px;line-height: 15px;margin-bottom: 10px; padding-left: 0;}
.small_text_title{ font-size: 11px; line-height: 12px;}

.modal{z-index: 9999;}
.cameraBox{width: 100% !important;}

.webcam-container {
    position: relative;
    width: 100%;
    height: 50vh; /* Full height of the viewport */
  }

.back_arrow{ position: absolute;
    left: 20px;
    top: 15px;
    z-index: 99;}
.back_arrow img{height: 20px;cursor: pointer;}
.latlng-wrapper{
    position: relative;
    top: -55px;
    left: 0px;
    width: 141px;
    background: #e7e5e5;
    font-size: 12px;
    margin: 0px;
}



     
.mspace_5{margin-left: 4px; margin-right:4px;}

.project_header{background: #f7f7f7;
    padding: 15px 15px;
    border: 1px solid #d7d7d7;
    margin-bottom: 15px;
}
.project_header .sub_project_title{background: #fff; border: 1px solid #d7d7d7; padding: 15px;}

.small_box_center{font-size: 11px;
    line-height: 12px; text-align: center;
    position: relative;
    display: block;
    line-height: 20px;
    border-right: 1px solid #d7d7d7;
}

.no_border_right{border-right: 0px solid #d7d7d7;}
.no_padding_right_0{padding-right: 0px !important;}


.footer_button{
    position: fixed;
    bottom: 0;
    text-align: center;
    width: 100%;
    background: #f7f7f7;
    left: 0;
    padding: 5px 0; border-top: 1px solid #d7d7d7;
}


.image_wrpper{width: 100%; text-align: center; border: 1px solid #d7d7d7;}
.image_wrpper img{height: 300px;}


  @media screen and (orientation: portrait) {
    .webcam-container {
      width: 100%;
      height: 100vh; /* Half height of the viewport in portrait mode */
    }
    .webcam-video {
        width: 100%;
        height: 100vh; /* Half height of the viewport in portrait mode */
      }
  }
  
  @media screen and (orientation: landscape) {
    .webcam-container {
      width: 100%; /* Half width of the viewport in landscape mode */
      height: 100vh;
      margin: 0 auto; /* Center horizontally */
    }
    .webcam-video {
        width: 100%; /* Half width of the viewport in landscape mode */
      height: 100vh;
      margin: 0 auto; /* Center horizontally */
      }
  }
  .page-title{
    padding: 10px;
    background: #f7f7f7;
    text-align: center;
    color: #246d6f;
  }
  
  .table>tbody>tr>th {
    /*...all th attributes like padding etc*/
    background-color:#f1f1f1 !important;
}


