body{
	margin:0;
	background:#fff;
	font:600 16px/18px 'Open Sans',sans-serif;
}
*,:after,:before{box-sizing:border-box}
.clearfix:after,.clearfix:before{content:'';display:table}
.clearfix:after{clear:both;display:block}
a{color:inherit;text-decoration:none}

.login-wrap{
	width:100%;
	margin:auto;
	max-width:100%;
	min-height:670px;
	position:relative;
	/*background:url(https://raw.githubusercontent.com/khadkamhn/day-01-login-form/master/img/bg.jpg) no-repeat center;*/
	background:url(../img/banner3.jpg) no-repeat center;
	box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);background-size: cover;
}
.login-html{
	width:100%;
	height:100%;
	position:absolute;
	padding:90px 70px 50px 70px;
	/*background:rgba(40,57,101,.9);*/
	background:rgba(0,128,131,.8);
}
.login-html .sign-in-htm,
.login-html .sign-up-htm{
	top:0;
	left:0;
	right:0;
	bottom:0;
	position:absolute;
	/*transform:rotateY(180deg);*/
	transform:rotateY(0deg);
	-webkit-backface-visibility:hidden;
	        backface-visibility:hidden;
	transition:all .4s linear;
}
.login-html .sign-in,
.login-html .sign-up,
.login-form .group .check{
	display:none;
}
.login-html .tab,
.login-form .group .label,
.login-form .group .button{
	text-transform:uppercase;
}
.login-html .tab{
	font-size:22px;
	margin-right:15px;
	padding-bottom:5px;
	margin:0 15px 10px 0;
	display:inline-block;
	border-bottom:2px solid transparent;
}
.login-html .sign-in:checked + .tab,
.login-html .sign-up:checked + .tab{
	color:#fff;
	border-color:#1161ee;
}
.login-form{
	min-height:345px;
	position:relative;
	perspective:1000px;
	transform-style:preserve-3d;
}
.login-form .group{
	margin-bottom:15px;
}
.login-form .group .label,
.login-form .group .input,
.login-form .group .button{
	width:100%;
	color:#fff;
	display:block;
}
.login-form .group .input,
.login-form .group .button{
	border:none;
	padding:15px 20px;
	border-radius:25px;
	background:rgba(255,255,255,.4);
    font-weight: normal; font-size: 14px;
}
.login-form .group input[data-type="password"]{
	text-security:circle;
	-webkit-text-security:circle;
}
.login-form .group .label{
	color:#fff;
	font-size:12px;
}
.login-form .group .button{
	/*background:#1161ee;*/
	background:#ea7826;
}
.login-form .group label .icon{
	width:15px;
	height:15px;
	border-radius:2px;
	position:relative;
	display:inline-block;
	background:rgba(255,255,255,.1); visibility: hidden;
}
.login-form .group label .icon:before,
.login-form .group label .icon:after{
	content:'';
	width:10px;
	height:2px;
	background:#fff;
	position:absolute;
	transition:all .2s ease-in-out 0s;
}
.login-form .group label .icon:before{
	left:3px;
	width:5px;
	bottom:6px;
	transform:scale(0) rotate(0);
}
.login-form .group label .icon:after{
	top:6px;
	right:0;
	transform:scale(0) rotate(0);
}
.login-form .group .check:checked + label{
	color:#fff;
}
.login-form .group .check:checked + label .icon{
	background:#1161ee;
}
.login-form .group .check:checked + label .icon:before{
	transform:scale(1) rotate(45deg);
}
.login-form .group .check:checked + label .icon:after{
	transform:scale(1) rotate(-45deg);
}
.login-html .sign-in:checked + .tab + .sign-up + .tab + .login-form .sign-in-htm{
	transform:rotate(0);
}
.login-html .sign-up:checked + .tab + .login-form .sign-up-htm{
	transform:rotate(0);
}

.hr{
	height:2px;
	margin:60px 0 50px 0;
	background:rgba(255,255,255,.2);
}
.foot-lnk{
	text-align:center;
}


.ui-overlay-a,  .ui-page-theme-a,  .ui-page-theme-a .ui-panel-wrapper{text-shadow: 0 0px 0 #f3f3f3;}
.ui-page-theme-a .ui-body-inherit{    background-color: transparent;}
.ui-input-text{border-width: 0px;}
.ui-page-theme-a .ui-btn{border-radius: 25px; background: #4874f1;color: #fff;text-shadow: 0 0px 0 #f3f3f3; border-color: #4874f1;}
.ui-page-theme-a a{color: #fff;font-weight: 700;}

.ui-page-theme-a .ui-btn:hover{
    background-color: #0b5ed7;
    border-color: #0a58ca;
    color: #fff;
    text-shadow: 0 0px 0 #f3f3f3;
}

h1.login_title{text-align: center; color: #fff; font-size: 24px;}
.login_header{background: #fff; width: 100%; text-align: center; margin-bottom: 20px;
    border-radius: 20px;-o-border-radius: 20px;-moz-border-radius: 20px;-webkit-border-radius: 20px;
}
.logo_line {
    width: 2px;
    height: 90px;
    background: #e0a554;
    position: relative;
    display: inline-block;
}

.logo_border{border-right: 2px solid #e0a554; margin-right: 8px;}
.foot-lnk a{color: #fff;}

.powered_by_text{font-weight: normal; margin-top: 40px;display: block;}

@media (max-width: 991px){
    .login-html {padding: 90px 30px 50px 30px;}
    .login-wrap{height: 100vh;min-height: inherit;}
    .hr {margin: 20px 0 20px 0;}
    h1.login_title{font-size: 18px;}
}


.error{font-size: 12px; color: red; text-align: center;}

